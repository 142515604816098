
.fs_normal_select .css-1okebmr-indicatorSeparator{
    display: none;
}
.fs_normal_select{
    min-width: 200px;
    max-width: 200px;
	margin-right:10px;
	font-size:14px;
}
.fs_normal_common > div > div {
    cursor: pointer;
}