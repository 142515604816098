/* Login
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.login {
	width: 560px;
	/* min-height: 250px; */
	min-height: 213px;
	margin-top: 100px;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: relative;
	border: 1px solid var(--medium-blue);
	border-radius: 5px;
}

.login form {
	margin-bottom: 0px;
}

.login .login-row {
	align-items: center;
	justify-content: space-between;
	display: flex;
}

.login .login-error-row {
	text-align: center;
}

.login .login-error-row .error-msg {
	color: red;
}

.login .login-info-row {
	text-align: center;
}

.login .login-info-row .info-msg {
	color: black;
	font-weight: bold;
}

.login input[type="submit"] {
	width: 100%;
	height: 37px;
	font-size: 15px;
}

.login input[type="submit"].disabled {
	background-color: #ccc;
}

.login .login-panel {
	padding: 30px;
	background-color: white;
	border-radius: 4px;
	box-shadow: 10px 10px 5px -5px rgba(0, 0, 0, 0.05);
	-webkit-box-shadow: 10px 10px 5px -5px rgba(0, 0, 0, 0.05);
	-moz-box-shadow: 10px 10px 5px -5px rgba(0, 0, 0, 0.05);
}

.login input[type="submit"] {
	display: inline-block;
	font-weight: 700;
	padding: 0.4rem 1.6rem;
	margin: 0;
	color: #fff;
	text-transform: uppercase;
	box-shadow: 0px 0px 20px 0px rgb(17 24 31 / 0%);
	text-decoration: none;
	background-color: var(--medium-blue);
	cursor: pointer;
	box-sizing: border-box;
	outline: 0;
	border: 0;
	border-radius: 4px;
}

.login input[type="text"],
.login input[type="password"],
.login input[type="email"] {
	height: 38px;
	padding: 6px 10px;
	background-color: #fff;
	border: 1px solid var(--medium-blue);
	border-radius: 4px;
	box-shadow: none;
	box-sizing: border-box;
	margin-bottom: 0;
	max-width: 80%;
	width: 80%;
}

.login input[type="text"] {
	background-color: var(--light-blue);
}

.login label {
	display: inline-block;
	margin-bottom: 0;
	font-weight: 400;
	font-size: 14px;
	line-height: 1;
	white-space: nowrap;
	margin-right: 0rem;
}

.login .login_panel_top {
	padding-top: 0px;
	height: 100%;
	margin-bottom: 30px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	width: 100%;
	font-size: 25px;
	font-weight: 700;
}

.login .status-msg {
	height: 105px;
	text-align: center;
}

/* ------- app selector ------- */

.app_selector_panel {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background-color: var(--white-color);
	border-radius: 4px;
	box-shadow: 10px 10px 5px -5px rgba(0, 0, 0, 0.05);
	-webkit-box-shadow: 10px 10px 5px -5px rgba(0, 0, 0, 0.05);
	-moz-box-shadow: 10px 10px 5px -5px rgba(0, 0, 0, 0.05);
}

.app_selector_panel_top {
	display: flex;
	flex-direction: row;
	justify-content: center;
	width: 100%;
	height: 120px;
	font-size: 30px;
	font-weight: 700;
	padding-top: 30px;
}

.app_selector_panel_mid {
	height: 20%;
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.app_selector_panel_mid_text {
	display: flex;
	flex-direction: column;
	justify-content: center;
	font-size: 22px;
	font-weight: 700;
	padding-top: 10px;
	color: var(--grey-color);
}

.app_selector_panel_bottom {
	height: 70%;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	margin-top: 10px;
	margin-bottom: 10px;
}

.login_app_btn {
	background-color: var(--medium-blue);
	text-transform: uppercase;
	border: none;
	border-radius: 5px;
	padding: 10px 12px;
	color: var(--white-color);
	font-size: 16px;
	font-weight: 700;
	margin: 15px 20px 15px 20px;
	display: flex;
	align-items: center;
	width: 200px;
	flex-direction: row;
	justify-content: center;
	cursor: pointer;
}

/* -------------- ACCOUNT SELECTOR --------------------- */
.login-panel .fs_normal_select {
	width: 80%;
	max-width: 80%;
}